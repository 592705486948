import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { BsCopy, BsDownload, BsSend } from "react-icons/bs";
import loaderGif from "./../assets/loader.gif";
import MobileNumberPopup from "../components/MobileNumberPopup";
import { FcNext, FcPrevious } from "react-icons/fc";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyName, companyKey, URLClientID, companyId } = location.state;

  console.log("Data >>> ", location.state);

  const [formData, setFormData] = React.useState("");
  const [link, setLink] = React.useState("");
  const [frameUrl, setFrameUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [downloadError, setDownloadError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData(value);
  };

  const getiFrameUrl = () => {
    axios
      .get(
        `https://nodeapi.smartdietplanner.com/api/tmsdp/getFrameUrl/${location.state?.companyId}`
      )
      .then((res) => {
        console.log("Urll >> ", res.data);
        setFrameUrl(res.data.url);
      });
  };

  // Use useEffect to set up the interval
  React.useEffect(() => {
    if (location.state === null) {
      navigate("/");
      return;
    }
    // Call the API immediately when the component mounts
    if (frameUrl === "") {
      getiFrameUrl();
    }

    // Simulating fetching records from an API or database
    if(companyName ==="trunativ"){
      fetchedWpRecords(7);
    }

    // Set up the interval to call the API every 2 minutes (120000 milliseconds)
    // const intervalId = setInterval(() => {
    //   getiFrameUrl();
    // }, 120000);
    // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    setError("");

    if (loading) {
      return;
    }

    if (link !== "") {
      setFormData("");
      setLink("");
    } else {
      if (formData === "") {
        alert("Id required");
      } else {
        setLoading(true);

        const timer = setTimeout(() => {
          console.log("\n<<<---- Timmed out(1 minute) ---->> ");
          setLoading(false);
          setError("The request is taking too long, please try again later.");
        }, 60000);

        const body = {
          companyKey: companyKey,
          userId: formData,
          URLClientID: URLClientID,
          companyId: companyId
        };

        try {
          const res = await axios.post(
            "https://nodeapi.smartdietplanner.com/api/tmsdp/genLink",
            body
          );
          console.log("API response >> ", res.data);
          clearTimeout(timer);
          setLink(res.data.url);
          setLoading(false);
        } catch (error) {
          console.error("Error: ", error);
          clearTimeout(timer);
          setError("An error occurred while fetching data.");
          setLoading(false);
        }
      }
    }
  };

  const downloadBtnHandler = async () => {
    if (downloading) {
      return;
    }

    setDownloading(true); // Show loader here
    setDownloadError("");

    try {
      // Validate if userId exists inside company
      const useResponse = await axios.get(
        `https://nodeapi.smartdietplanner.com/api/tmsdp/getShortUrlMappingRecords?companyId=${companyId}`
      );
      const data = useResponse?.data;

      downloadCSV(data, fileHeaders);
    } catch (error) {
      console.error(error);
      setDownloadError("An error occurred. Please try again.");
    } finally {
      setDownloading(false); // Hide loader only after all operations are complete
    }
  };

  const handleDelete = async () => {
    if (!userId.trim()) {
      setErrorMessage("User ID cannot be empty.");
      return;
    }

    setLoading(true); // Show loader here
    setErrorMessage("");
    setSuccessMessage("");
    const raw = {
      profile: {
        email: userId,
      },
    };

    try {
      // Validate if userId exists inside company
      const useResponse = await axios.get(
        `https://nodeapi.smartdietplanner.com/api/profile/getAll?id=${userId}`
      );
      const data = useResponse?.data;

      if (data.length > 0) {
        const profile = data[0]?.profile;

        if (profile.companyId !== companyId) {
          setErrorMessage("You do not have access to this user.");
        } else {
          const response = await axios.post(
            `https://nodeapi.smartdietplanner.com/api/tmsdp/genToken?key=${companyKey}`,
            raw
          );

          const token = response?.data?.access_token;

          const result = await axios.get(
            "https://app.smartdietplanner.com/api/customer/profile/remove",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (result.data.code === "0000") {
            setSuccessMessage("Account deleted successfully.");
            setUserId("");
          } else {
            setErrorMessage("Failed to delete account. Please try again.");
          }
        }
      } else {
        setErrorMessage("Account does not exist. Please check & try again.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Hide loader only after all operations are complete
    }
  };

  const handleLogout = () => {
    navigate("/", { replace: true });
  };

  // Define column headers for CSV
  const fileHeaders = [
      "_id",
      "userId",
      "companyId",
      "shortUrl",
      "createdOn"
  ];

  // Function to convert JSON to CSV string
  function convertJSONToCSV(jsonData, columnHeaders) {
    // Check if JSON data is empty
    if (jsonData.length === 0) {
      return '';
    }

    const headings = companyName ==="trunativ" ? ["Id", "Name", "Mobile Number", "Url", "Date"] : [
      "Id",
      "User Id",
      "Company Id",
      "Short Url",
      "Created on"
    ];

    // Create headers string
    const headers = headings.join(',') + '\n';

    console.log("266:: JSON Data ::-->> ", jsonData);

    // Map JSON data to CSV rows
    const rows = jsonData
      .map((row) => {
        // Map each row to CSV format
        return columnHeaders.map((field) => row[field] || '').join(',');
      })
      .join('\n');

    // Combine headers and rows
    return headers + rows;
  }

  // Function to initiate CSV download
  function downloadCSV(jsonData, headers) {
    console.log("DownloadCCsv :-->> ", jsonData);
    const csvData = convertJSONToCSV(jsonData, headers);

    // Check if CSV data is empty
    if (csvData === '') {
      alert('No data to export');
    } else {
      // Create CSV file and initiate download
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'url_mapping.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const  isRegisteredUser = async (e) => {
    e.preventDefault();
    if (formData === "") {
      alert("Id required");
      return;
    }
    setError("");

    if (loading) {
      return;
    }

    if (link !== "" || message) {
      setFormData("");
      setLink("");
      setMessage(null);
      return;
    }
    setLoading(true);

    let query = `userId=${formData}`;
    if (companyId === "smartdietplanner") {
      query += `&companyId=*`;
    } else {
      query += `&companyId=${companyId}`;
    }

    const profileCanBeAdded = await axios.get( 'https://nodeapi.smartdietplanner.com/api/dietitian/canAddProfile?' + query);

    if(!profileCanBeAdded?.data?.status){
      setMessage(profileCanBeAdded?.data?.message);
      setLoading(false);
      return;
    }

    handleSubmit(e);
    
    // let clientIdSystem = URLClientID;
    // if (localStorage.getItem("loginEmail") === "fitelo") {
    //   this.key = "FITELO2023";
    //   clientIdSystem = "fitelo";
    // }
  
    /*
    console.log(formData);
    const payload = {
      profile: {
        email: formData,
      },
    };

    this.appS
      .getTokenExternal(this.key, formData)
      .subscribe((res1) => {
        // User is registered
        if (res1["code"] === "0000") {
          const lnk = `https://onboarding.smartdietplanner.com/read?token=${res1["accessToken"]}&clientId=${clientIdSystem}&type=1`;

          this.getTinyUrl(lnk);
          this.apiHitted = true;
          this.userExisted = true;
        } else {
          // User not registered
          this.appS.externalRegistration(this.key, payload).subscribe(
            (res) => {
              if (res["code"] !== "0000") {
                this.utilities.hideLoader();
                this.utilities.presentAlert(res["message"]);
              } else {
                const lnk = `https://onboarding.smartdietplanner.com/read?token=${res["access_token"]}&clientId=${clientIdSystem}&type=1`;

                this.getTinyUrl(lnk);
                this.apiHitted = true;
                this.userExisted = false;
                this.newUserAddAction();
              }
              if (localStorage.getItem("dietitianId") != null) {
                this.templateService
                  .handleDietitianAction(
                    localStorage.getItem("dietitianId"),
                    "668d74731073b512dd2b9850",
                    formData,
                    "Add New User",
                    localStorage.getItem("companyId")
                  )
                  .subscribe((dRespondata) => {
                    console.log("success", res["access_token"]);
                  });
              }
            },
            (err) => {
              this.utilities.hideLoader();
              console.log("error", err);
            }
          );
        }
      });
    */
  }
  
  const [records, setRecords] = useState([]);
  const [selectedDays, setSelectedDays] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const fetchedWpRecords = (day) => {
    axios.get(`https://nodeapi.smartdietplanner.com/api/wp/records?days=${day}&company=TRUNATIV%402024`).then(res=> {
      console.log("Data ::> ", res.data.response);
      const arr = res.data.response;
      setRecords(arr);
      setCurrentRecords(res.data.response.slice(0, recordsPerPage));
    }).catch(err=> {
      alert("Something went wrong");
      console.error("Error: ", err);
    })
  }


  const handleDaysChange = (event) => {
    const days = parseInt(event.target.value);
    setSelectedDays(days);
    fetchedWpRecords(days);

    // const today = new Date();
    // const filteredRecords = allRecords.filter((record) => {
    //   const recordDate = new Date(record.date);
    //   const diffDays = Math.floor((today - recordDate) / (1000 * 60 * 60 * 24));
    //   return diffDays <= selectedDays;
    // });
    // setRecords(filteredRecords);
  };

  // Handle page change
  const handleNextPage = () => {
    if (currentPage * recordsPerPage < records.length) {
      setCurrentRecords(records.slice(recordsPerPage*currentPage + 10, recordsPerPage*currentPage + 20 ))
      setCurrentPage(currentPage + 1);
      // const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentRecords(records.slice(recordsPerPage*currentPage - 10, recordsPerPage*currentPage + 10 ))
      setCurrentPage(currentPage - 1);
      // const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    }
  };

  return (
    <div className="w-full h-full py-5 px-4">
      <div className="flex justify-between">
        <img
          src="https://fitrofy.com/wp-content/uploads/2023/04/mainlogo.webp"
          alt="logo"
          className=""
        />

        <div className={`flex items-center justify-between w-[15%]`}>
          <p className="font-semibold text-xl">v1.1</p>
          <button
            className="border-2 bg-blue-500 py-2 px-4 rounded-full my-auto font-bold text-white"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
      <h2 className="text-xl font-semibold mt-5">Welcome {companyName}!</h2>

      <div className="p-10 pt-0 border-2 border-black mt-10 mx-10">
        <form onSubmit={isRegisteredUser} className="flex flex-col items-center">
          <h2 className="text-xl font-semibold my-5 underline">
            Get Customer Link/Send Message
          </h2>
          <div className="flex gap-2 mb-5">
            <label htmlFor="userName" className="text-lg font-medium">
              Profile Id:
            </label>
            <input
              type="text"
              id="userName"
              name="userName"
              placeholder="Enter the Profile ID"
              value={formData}
              autoComplete="email"
              onChange={handleChange}
              className="border px-2"
            />
          </div>
          <button
            type="submit"
            className="mt-8 text-center w-[15rem] text-lg font-medium bg-blue-300 rounded-full py-2 mx-auto"
          >
            {loading ? (
              <img src={loaderGif} alt="loader" className="w-10 mx-auto" />
            ) : (link || message!==null) ? (
              "Clear"
            ) : (
              "Generate Link"
            )}
          </button>
          {error && <p className="text-red-500">Error: {error}</p>}
        </form>
        {link && (
          <>
            <div className="flex mt-5 items-center gap-5">
              <span className="text-lg">Link generated::</span>

              <textarea
                name="link"
                id=""
                cols="110"
                rows="1"
                value={link}
                readOnly
                className="px-2"
              />
            </div>
            <div className="flex gap-10">
              <button
                className="border-2 border-blue-600 rounded-full py-2 px-8 flex items-center gap-2 mt-2"
                onClick={() => {
                  // Use the Clipboard API to copy text
                  navigator.clipboard
                    .writeText(link)
                    .then(() => {
                      alert("Text copied to clipboard!");
                    })
                    .catch((err) => {
                      console.error("Failed to copy: ", err);
                    });
                }}
              >
                Copy
                <BsCopy />
              </button>

              {companyKey === "BLUEBEIN2024" && "yes" && (
                <button
                  className="border-2 border-blue-600 rounded-full py-2 px-8 flex items-center gap-2 mt-2"
                  onClick={showPopup}
                >
                  Send Message
                  <BsSend />
                </button>
              )}

              {isPopupVisible && (
                <MobileNumberPopup
                  onClose={closePopup}
                  data={formData}
                  link={link}
                />
              )}
            </div>
          </>
        )}
        {message && (
          <>
            <div className="flex mt-5 items-center gap-5">
              <span className="text-lg text-red-500">{message.replace(/dietitian/gi, "Company")}</span>
            </div>
          </>
        )}
      </div>


      {companyName ==="trunativ" && (<div className="p-10 pt-0 border-2 border-black mt-10 mx-10 flex flex-col items-center">
      
          <h2 className="text-xl font-semibold my-5 underline">
            Record Viewer
            <button
              onClick={()=>downloadCSV(records, ["_id","name","phone", "tinyUrl", "sent_at" ])}
              type="submit"
              className="ml-10 mt-8 text-center w-[15rem] text-lg font-medium bg-blue-300 rounded-full py-2 mx-auto"
            >
              {downloading ? (
                <img src={loaderGif} alt="loader" className="w-10 mx-auto" />
              ) : (
                <div className="flex justify-center items-center gap-3"><BsDownload/> Download CSV</div>
              )}
            </button>
            {downloadError && <p className="text-red-500">Error: {downloadError}</p>}
          </h2>
          <div className="flex gap-4">
            <label className="text-lg font-medium">Select Days: </label>
            <select onChange={handleDaysChange} value={selectedDays} className="cursor-pointer px-2">
            <option value={1}>Last 1 Day</option>
            <option value={2}>Last 2 Days</option>
            <option value={3}>Last 3 Days</option>
            <option value={7}>Last 7 Days</option>
            <option value={10}>Last 10 Days</option>
            <option value={15}>Last 15 Days</option>
            <option value={21}>Last 21 Days</option>
            <option value={30}>Last 1 Month</option>
            <option value={60}>Last 2 Months</option>
            </select>
            <span className="ml-5 text-lg font-medium">No. of Records: {records.length} </span>
          </div>

      <table border="1" className="border" style={{ marginTop: "20px", width: "100%" }}>
        <thead className="border">
          <tr >
            <th className="border border-black p-2">Name</th>
            <th className="border border-black p-2">Mobile Number</th>
            <th className="border border-black p-2">Url</th>
            <th className="border border-black p-2">Date</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((record) => (
            <tr key={record._id}>
              <td className="border border-black p-2">{record.name}</td>
              <td className="border border-black p-2 text-center">{record.phone.slice(-10)}</td>
              <td className="border border-black p-2">{record.tinyUrl}</td>
              <td className="border border-black p-1 text-center">{new Date(record.sent_at).toLocaleDateString()}</td>
              {/* <td className="border border-black p-1">{new Date(record.sent_at).toLocaleString()}</td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-10 flex justify-between w-1/2">
      {currentPage >1 ? <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="flex items-center gap-4"
        >
          <FcPrevious color="#000"/> Previous
        </button> : <div></div> }
        {currentPage * recordsPerPage < records.length ?
        <button
          onClick={handleNextPage}
          disabled={currentPage * recordsPerPage >= records.length}
          className="flex items-center gap-4"
        >
          Next <FcNext />
        </button>: <div></div>}
      </div>

      </div>)}

      {companyName ==="origin" && (<div className="p-10 pt-0 border-2 border-black mt-10 mx-10 flex flex-col items-center">
          <h2 className="text-xl font-semibold my-5 underline">
            Short Url Mapping
          </h2>
          <button
            onClick={()=>downloadBtnHandler()}
            type="submit"
            className="mt-8 text-center w-[15rem] text-lg font-medium bg-blue-300 rounded-full py-2 mx-auto"
          >
            {downloading ? (
              <img src={loaderGif} alt="loader" className="w-10 mx-auto" />
            ) : (
              <div className="flex justify-center items-center gap-3"><BsDownload/> Download</div>
            )}
          </button>
          {downloadError && <p className="text-red-500">Error: {downloadError}</p>}
      </div>)}
      {companyName === "Plix" && (
        <div className="border-2 border-black mt-10 mx-10">
          <div className="w-full bg-white rounded-lg shadow-lg p-8 flex flex-col items-center">
            <h2 className="text-xl font-semibold my-5 underline">
              Delete Account
            </h2>
            <div className="flex gap-2 mb-5">
              <label htmlFor="userId" className="text-lg font-medium">
                Enter User ID:
              </label>
              <input
                type="text"
                id="userId"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                className="border focus:outline-none focus:ring-2 focus:ring-blue-500 px-2"
                placeholder="Enter User ID"
              />
            </div>
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="text-green-500 text-sm mb-4">{successMessage}</p>
            )}

            <button
              onClick={() => handleDelete()}
              className={`mt-8 text-center w-[15rem] text-lg font-medium rounded-full py-2 mx-auto bg-blue-300 transition ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <img src={loaderGif} alt="loader" className="w-10 mx-auto" />
              ) : (
                "Delete Account"
              )}
            </button>
          </div>
        </div>
      )}

      <div className="mt-10 border-2 h-[220rem] mx-10 mb-20 border-black">
        <h2 className="text-xl font-semibold my-5 underline text-center">
          Dashboard
        </h2>
        <iframe
          src={frameUrl}
          frameborder="0"
          title="Dashboard"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default HomePage;
